import '@/styles/globals.css';
import 'leaflet/dist/leaflet.css';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Analytics } from '@vercel/analytics/react';

export default function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  return (
    <>
      <SessionProvider
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        session={session}
      >
        <Head>
          <title>Komète AKTO</title>
          <meta name="description" content="Komète AKTO par KYU Associés" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="/favicon.ico" />
        </Head>
        <main>
          <Analytics />
          <Component {...pageProps} />
        </main>
      </SessionProvider>
    </>
  );
}
